import React from "react";
import GoogleMapReact from "google-map-react";

const SimpleMap = (props) => {
  const { height, width, lat, lgn, title } = props;
  const mapObj = {
    center: {
      lat: Number(lat) || 40.4378698,
      lng: Number(lgn) || -3.8196207,
    },
    city: "",
  };

  const renderMarkers = (map, maps) => {
    new maps.Marker({
      position: mapObj.center,
      map,
      title: title,
    });
  };

  return (
    <div style={{ height: height, width: width }}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals={true}
        bootstrapURLKeys={{ key: "AIzaSyDy80slwpOlrhOhU-qLYFmGKN3FWgAsmGA" }}
        center={mapObj.center}
        defaultZoom={11}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  );
};

export default SimpleMap;
