export const formaterPrice = (
  price: any,
  localeCode: string,
  currecy: string
) => {
  const getCurrency = ()=>{
    if(localStorage.getItem("domain") && localStorage.getItem("domain") === "blacksheeprestaurants.com"){
      return "HKD";
    } else {
      return "EUR";
    }
  }

  const getLocale = ()=>{
    if(localStorage.getItem("domain") && localStorage.getItem("domain") === "blacksheeprestaurants.com"){
      return "zh-HK";
    } else {
      return "es-ES";
    }
  }
  
  const priceFormater = new Intl.NumberFormat(
    getLocale(),
    {
      style: "currency",
      currency: getCurrency(),
    }
  ).format(price);



  return priceFormater;
};
