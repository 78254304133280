import React from "react";
import "./index.css";

export default function Items(props: any) {
  const { data, title } = props;

  const pymentTipe = () => {
    if (data.paymentMethod === "Paypal") {
      return "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/paypal.png";
    } else if (data.paymentMethod === "Tarjeta de credito") {
      return "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/targetas.png";
    } else if (data.paymentMethod === "Efectivo") {
      return "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/dinero.png";
    } else if (data.paymentMethod === "Apple Pay") {
      return "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/apple+.png";
    } else if (data.paymentMethod === "Google Pay") {
      return "https://internal-image-wilbby.s3.eu-west-3.amazonaws.com/google.png";
    }
  };

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-paymentMethod">
        <div>
          <img
            src={pymentTipe()}
            alt={data.paymentMethod}
            className="img_payment"
          />
        </div>
        <div>
          <p>{data.paymentMethod}</p>
        </div>
      </div>
    </div>
  );
}
