import React from "react";
import { Query } from "react-apollo";
import { query } from "./GraphQL";

const Session = (Component) => (props) => (
  <Query query={query.RESTAURANT}>
    {({ loading, error, data, refetch }) => {
      if (loading) return null;
      if (error) return error;
      return <Component {...props} refetch={refetch} session={data} />;
    }}
  </Query>
);

export default Session;
