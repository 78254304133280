import { useState } from "react";
import { Button, message, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useMutation, useQuery } from "react-apollo";
import { mutations, query } from "../../GraphQL";
import moment from "moment";
import HorarioReserva from "./Horario";
import i18n from "../../i18n";
import { PRIMARY_COLOR } from "../../Utils/Urls";

export default function Reservas({ user }: any) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [visible, setvisible] = useState(false);
  const domain = localStorage.getItem("domain");
  const [NewReservaProceed] = useMutation(mutations.UPDATE_RESERVA);

  const {
    data = {},
    loading,
    refetch,
  } = useQuery(query.GET_RESERVAS, {
    variables: {
      status: [],
      isUser: false,
      isAdmin: false,
      page: page,
      limit: limit,
      domain: domain,
      language: i18n.language,
    }
  });

  const { getAllBookin } = data;

  const datos = getAllBookin ? getAllBookin.data : [];
  const total = getAllBookin ? getAllBookin.count : 0;

  const handleCancel = () => {
    setvisible(false);
  };

  const onChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setLimit(pageSize);
  };

  const updateReserva = (id: string, status: string) => {
    NewReservaProceed({ variables: { id: id, status: status, domain: domain } })
      .then((res: any) => {
        if (res.data.NewReservaProceed.success) {
          refetch();
          message.success(i18n.t("reserva:successBooking"));
        } else {
          message.warning(i18n.t("reservas:somethingWentWrong"));
        }
      })
      .catch((e: any) => {
        message.error(JSON.stringify(e));
      });
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "Nueva":
        return <Tag color="purple">{status}</Tag>;
      case "Confirmada":
        return <Tag color="purple">{status}</Tag>;
      case "Finalizada":
        return <Tag color="lime">{status}</Tag>;
      case "Cancelada":
        return <Tag color="red">{status}</Tag>;
      case "Cancelada por el cliente":
        return <Tag color="red">{status}</Tag>;
      case "Cancelada por el establecimiento":
        return <Tag color="red">{status}</Tag>;
      default:
        return <Tag color="purple">{status}</Tag>;
    }
  };

  const renderBtn = (item: any) => {
    switch (item.status) {
      case "Nueva":
        return (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => updateReserva(item._id, "Confirmada")}
            >
              {i18n.t("reservas:confirm")}
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => updateReserva(item._id, "Cancelada")}
            >
              {i18n.t("reservas:cancel")}
            </Button>
          </Space>
        );
      case "Confirmada":
        return (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => updateReserva(item._id, "Finalizada")}
            >
              {i18n.t("reservas:finish")}
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => updateReserva(item._id, "Cancelada")}
            >
              {i18n.t("reservas:cancel")}
            </Button>
          </Space>
        );
      case "Finalizada":
        return (
          <Space size="middle">
            <Button type="primary" disabled>
              {i18n.t("reservas:finished")}
            </Button>
          </Space>
        );
      case "Cancelada":
        return (
          <Space size="middle">
            <Button type="primary" disabled>
              {i18n.t("reservas:cancelled")}
            </Button>
          </Space>
        );
      case "Cancelada por el cliente":
        return (
          <Space size="middle">
            <Button type="primary" disabled>
              {i18n.t("reservas:cancelledByCustomer")}
            </Button>
          </Space>
        );
      case "Cancelada por el establecimiento":
        return (
          <Space size="middle">
            <Button type="primary" disabled>
              {i18n.t("reservas:cancelledByStore")}
            </Button>
          </Space>
        );
      default:
        return (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => updateReserva(item._id, "Confirmada")}
            >
              {i18n.t("reservas:confirm")}
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => updateReserva(item._id, "Cancelada")}
            >
              {i18n.t("reservas:cancel")}
            </Button>
          </Space>
        );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: i18n.t("reservas:date"),
      key: "date",
      render: (item: any) => {
        return (
          <div>
            <h3 style={{ fontWeight: "bold" }}>
              {moment(item.date).format("ll")}
            </h3>
            <span style={{ color: PRIMARY_COLOR }}>{i18n.t("reservas:time")} {": "} {item.hour}</span>
            <br />
            <span style={{ color: "gray" }}>
              {i18n.t("reservas:entry")} {": "} {moment(item.created_at).format("ll")}
            </span>
          </div>
        );
      },
    },
    {
      title: i18n.t("reservas:numberOfPeople"),
      dataIndex: "people",
      key: "people",
      render: (item: any) => {
        return <p>{item} {" "} {i18n.t("reservas:people")}</p>;
      },
    },
    {
      title: i18n.t("reservas:customer"),
      dataIndex: "user",
      key: "user",
      render: (item: any) => {
        return (
          <div>
            <h3 style={{ fontWeight: "bold" }}>
              {item.name} {item.lastName}
            </h3>
            <span style={{ color: "gray" }}>{item.email}</span>
            <br />
            <span style={{ color: PRIMARY_COLOR }}>Tel: {item.telefono.startsWith("+") ? item.telefono : `+${item.telefono}`}</span>
          </div>
        );
      },
    },

    {
      title: i18n.t("reservas:state"),
      key: "status",
      dataIndex: "status",
      render: (status: string) => {
        return renderStatus(status);
      },
    },

    {
      title: i18n.t("reservas:note"),
      key: "note",
      dataIndex: "note",
      render: (note: any) => {
        return <p>{note ? note : i18n.t("reservas:hasNoNote")}</p>;
      },
    },
    {
      title:i18n.t("reservas:actions"),
      key: "btn",
      render: (items: any) => renderBtn(items),
    },
  ];

  return (
    <div>
      <div className="search_container">
        <Button
          type="primary"
          style={{ height: 45 }}
          onClick={() => setvisible(true)}
        >
          {i18n.t("reservas:editBookingSchedule")}
        </Button>
      </div>
      <Table
        scroll={{ x: "calc(100vh - 4em)" }}
        columns={columns}
        dataSource={datos}
        loading={loading}
        pagination={{
          defaultCurrent: 1,
          onChange: onChangePage,
          total: total,
          pageSize: limit,
        }}
      />

      <HorarioReserva
        visible={visible}
        handleCancel={handleCancel}
        user={user}
      />
    </div>
  );
}
