import "./index.css";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import i18n from "../../../i18n";

export default function Items(props: any) {
  const { data, title } = props;

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-horario">
        <p style={{ color: "gray" }}>
          {data ? data.cuponName : i18n.t('orders:notAvailable')}
        </p>
        <p style={{ color: "gray" }}>{data ? data.cuponTipe : i18n.t('orders:percentage')}</p>
        {data.cuponTipe === "porcentaje" ? (
          <p>{data.cuponValue}%</p>
        ) : (
          <p>{formaterPrice(data.discountTotal, "es-ES", "EUR")}</p>
        )}
      </div>
    </div>
  );
}
