import moment, { Moment } from "moment";
export const defaultTimeout = (
  Hour1: any,
  Minute1: any,
  Hour2: any,
  Minute2: any
): [Moment, Moment] => {
  const now = new Date();
  const open1 = new Date(now);
  const open2 = new Date(now);

  open1.setHours(Hour1);
  open1.setMinutes(Minute1);

  open2.setHours(Hour2);
  open2.setMinutes(Minute2);

  const dfault = [moment(open1), moment(open2)];

  //@ts-ignore
  return dfault;
};

export const onChangeHour = (
  value: any,
  setOpenHour1: any,
  setOpenMinute1: any,
  setCloseHour1: any,
  setCloseMinute1: any
) => {
  const dates = value ? value : [];
  const modayHora0 = moment(dates[0]);
  const modayHora1 = moment(dates[1]);
  var hour0 = modayHora0.hour();
  var time0 = modayHora0.minutes();
  var hour1 = modayHora1.hour();
  var time1 = modayHora1.minutes();

  setOpenHour1(hour0);
  setOpenMinute1(time0);
  setCloseHour1(hour1);
  setCloseMinute1(time1);
};

export const onChangeHour2 = (
  value: any,
  setOpenHour2: any,
  setOpenMinute2: any,
  setCloseHour2: any,
  setCloseMinute2: any
) => {
  const dates = value ? value : [];
  const modayHora0 = moment(dates[0]);
  const modayHora1 = moment(dates[1]);
  var hour0 = modayHora0.hour();
  var time0 = modayHora0.minutes();
  var hour1 = modayHora1.hour();
  var time1 = modayHora1.minutes();

  if (value) {
    setOpenHour2(hour0);
    setOpenMinute2(time0);
    setCloseHour2(hour1);
    setCloseMinute2(time1);
  } else {
    setOpenHour2(null);
    setOpenMinute2(null);
    setCloseHour2(null);
    setCloseMinute2(null);
  }
};
