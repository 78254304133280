import React from "react";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
  CheckOutlined,
  SyncOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import "./card.css";
import { Modal, Table, Tag, Button, message } from "antd";
import Details from "../DetailsCard";
import { useMutation } from "react-apollo";
import { mutations } from "../../../GraphQL";
import moment, { lang } from "moment";
import "moment/locale/es";
import { Countdown } from "../time";
import i18n from "../../../i18n";

const { confirm } = Modal;

export default function Card(props: any) {
  const {
    loading,
    datos,
    setpage,
    setlimit,
    onChangePage,
    refetch,
    limit,
    documents,
  } = props;
  const domain = localStorage.getItem("domain");
  const isKosmoIntegrated = localStorage.getItem("isKosmo");
  const timezoneOffset = domain === "blacksheeprestaurants.com" ? 480 : 120;

  const [NewOrdenProceed] = useMutation(mutations.NEW_ORDER_PROCEED);

  const returOrder = (orders: any) => {
    refetch();
    NewOrdenProceed({
      variables: {
        ordenId: orders._id,
        status: "Rechazada por la tienda",
        IntegerValue: 0,
        statusProcess: {
          status: "Rechazada por la tienda",
          date: moment(new Date()).utcOffset(120).format(),
        },
        domain: domain,
        language: i18n.language,
      },
    }).then((res: any) => {
      refetch();
      message.success(i18n.t("orders:orderMarkedAsReturned"));
    });
  };

  const proccessOrder = (orders: any, status: string, value: number) => {
    refetch();
    NewOrdenProceed({
      variables: {
        ordenId: orders._id,
        status: status,
        IntegerValue: value,
        statusProcess: {
          status: status,
          date: moment(new Date()).utcOffset(120).format(),
        },
        domain: domain,
        language: i18n.language,
      },
    })
      .then((res: any) => {
        refetch();
        message.success(i18n.t("orders:orderUpdated"));
      })
      .catch(() => {
        message.error(i18n.t("orders:somethingWentWrong"));
      });
  };

  const renderBTNStatus = (order: any) => {
    switch (order.status) {
      case "Nueva":
        return (
          <Button
            type="primary"
            onClick={() => proccessOrder(order, "Confirmada", 40)}
          >
            {i18n.t("orders:confirmOrder")}
          </Button>
        );

      case "Confirmada":
        return (
          <Button
            type="primary"
            onClick={() => proccessOrder(order, "En la cocina", 50)}
          >
            {i18n.t("orders:goToKitchen")}
          </Button>
        );

      case "En la cocina":
        return (
          <Button
            type="primary"
            onClick={() => proccessOrder(order, "Listo para recoger", 60)}
          >
            {i18n.t("orders:readyToPickup")}
          </Button>
        );

      case "Devuelto":
        return (
          <Button
            danger
            type="primary"
            onClick={() => null}
            disabled={order.status === "Devuelto"}
          >
            {i18n.t("orders:returned")}
          </Button>
        );

      case "Preparando para el envío":
        return (
          <Button
            danger
            type="primary"
            onClick={() => null}
            disabled={order.status === "Devuelto"}
          >
            {i18n.t("orders:preparingForDelivery")}
          </Button>
        );

      default:
        return (
          <Button type="primary" onClick={() => null}>
            {i18n.t("orders:orderCompleted")}
          </Button>
        );
    }
  };

  function showDeleteConfirm(data: any) {
    confirm({
      title: i18n.t("orders:confirmDeleteTitle"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("orders:confirmDeleteContent"),
      okText: i18n.t("orders:return"),
      okType: "danger",
      cancelText: i18n.t("orders:cancel"),
      onOk() {
        returOrder(data);
      },
      onCancel() {
        console.log(i18n.t("orders:cancel"));
      },
    });
  }

  const columns = [
    {
      title: i18n.t("orders:orderId"),
      dataIndex: "",
      key: "",
      render: (d: any) => {
        return (
          <div>
            <Tag color="#f50">#{d.channelOrderDisplayId}</Tag>
            <br />
            <p style={{ marginTop: 5 }}>
              <ClockCircleOutlined />{" "}
              {moment(d.created_at).utcOffset(timezoneOffset).format("HH:mm")}
            </p>
          </div>
        );
      },
    },
    {
      title: i18n.t("orders:customer"),
      dataIndex: "",
      key: "x",
      render: (d: any) => {
        return (
          <div>
            <p style={{ margin: 0, padding: 0 }}>
              {d.customerData.name} {d.customerData.lastName}
            </p>
            {d.orderIsAlreadyPaid ? null : (
              <Tag color="#87d068">{i18n.t("orders:cashPayment")}</Tag>
            )}

            {!d.orderIsAlreadyPaid ? <br /> : null}

            {d.orderType === "pickup" ? null : (
              <span style={{ color: "gray" }}>
                {d.deliveryAddressData.formatted_address},{" "}
                {d.deliveryAddressData.puertaPiso},{" "}
                {` ${d.deliveryAddressData.postalcode}`
                  ? d.deliveryAddressData.postalcode
                  : i18n.t("orders:noZipCode")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: i18n.t("orders:rider"),
      dataIndex: "",
      key: "x",
      render: (d: any) => {
        return (
          <div>
            {!d.storeData.autoshipping ? (
              <div>
                {d.orderType === "delivery" ? (
                  <div>
                    {d.courier ? (
                      <Button
                        type="dashed"
                        disabled={d.status === "Devuelto" ? true : false}
                      >
                        {d.courierData.name} {d.courierData.lastName}
                      </Button>
                    ) : (
                      <Button
                        type="dashed"
                        disabled={d.status === "Devuelto" ? true : false}
                      >
                        {i18n.t("orders:unassigned")}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button type="dashed" danger>
                    {i18n.t("orders:toPickup")}
                  </Button>
                )}
              </div>
            ) : (
              <Button type="dashed" danger>
                {i18n.t("orders:deliveredByStore")}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: i18n.t("orders:state"),
      dataIndex: "",
      key: "x",
      render: (d: any) => {
        const renderStatus = () => {
          switch (d.status) {
            case "Pendiente de pago":
              return (
                <Tag color="processing" icon={<ExclamationCircleOutlined />}>
                  {i18n.t("orders:pending_payment")}
                </Tag>
              );
            case "Enviada":
              return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {i18n.t("orders:sent")}
                </Tag>
              );
            case "Recogida":
              return (
                <Tag icon={<CheckCircleOutlined />} color="processing">
                  {i18n.t("orders:picked_up")}
                </Tag>
              );
            case "Fallida":
              return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {i18n.t("orders:failed")}
                </Tag>
              );
            case "Cancelada":
              return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {i18n.t("orders:canceled")}
                </Tag>
              );
            case "Devuelta":
              return (
                <Tag icon={<RollbackOutlined />} color="error">
                  {i18n.t("orders:returned_")}
                </Tag>
              );
            case "Devolviendo":
              return (
                <Tag icon={<SyncOutlined spin />} color="processing">
                  {i18n.t("orders:returning")}
                </Tag>
              );
            case "Rechazada por el rider":
              return (
                <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                  {i18n.t("orders:rejected_rider")}
                </Tag>
              );
            case "Rechazada por la tienda":
              return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {i18n.t("orders:rejected_store")}
                </Tag>
              );
            case "Resolución":
              return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {i18n.t("orders:resolution")}
                </Tag>
              );
            case "Devuelto":
              return (
                <Tag icon={<RollbackOutlined />} color="error">
                  {i18n.t("orders:returned")}
                </Tag>
              );
            case "Nueva":
              return (
                <Tag icon={<CheckOutlined />} color="warning">
                  {i18n.t("orders:new")}
                </Tag>
              );

            case "Entregada":
              return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {i18n.t("orders:delivered")}
                </Tag>
              );

            case "Finalizada":
              return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {i18n.t("orders:finished")}
                </Tag>
              );
            case "Confirmada":
              return (
                <Tag icon={<SyncOutlined spin />} color="success">
                  {i18n.t("orders:confirmed")}
                </Tag>
              );
            case "Por recoger":
              return (
                <Tag icon={<SyncOutlined spin />} color="processing">
                  {i18n.t("orders:to_pickup")}
                </Tag>
              );
            default:
              return (
                <Tag icon={<SyncOutlined spin />} color="processing">
                  {d.status}
                </Tag>
              );
          }
        };
        return (
          <div>
            {renderStatus()}{" "}
            <Countdown
              eventTime={Number(
                moment(d.scheduled ? d.deliveryTime : d.created_at)
                  .utcOffset(timezoneOffset)
                  .add(45, "minutes")
                  .format("X")
              )}
              interval={1000}
              scheduled={d.scheduled}
              status={d.status}
            />
          </div>
        );
      },
    },

    {
      title: i18n.t("orders:action"),
      dataIndex: "",
      key: "x",
      render: (data: any) => {
        return isKosmoIntegrated === "true"
          ? data.status === "Nueva" && (
              <Button
                type="primary"
                onClick={() => proccessOrder(data, "Confirmada", 40)}
              >
                {i18n.t("orders:confirmOrder")}
              </Button>
            )
          : renderBTNStatus(data);
      },
    },

    {
      title: i18n.t("orders:return"),
      dataIndex: "",
      key: "x",
      render: (data: any) => {
        return (
          <Button
            danger
            type="primary"
            onClick={() => showDeleteConfirm(data)}
            disabled={data.status === "Devuelto"}
          >
            {data.status === "Devuelto"
              ? i18n.t("orders:returned")
              : i18n.t("orders:rejectOrder")}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ x: "calc(100vh - 4em)" }}
      rowKey={(record) => record.channelOrderDisplayId}
      onChange={(pagination) => {
        setpage(pagination.current);
        setlimit(pagination.pageSize);
      }}
      columns={columns}
      loading={loading}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Details
              data={record}
              refetch={refetch}
              NewOrdenProceed={NewOrdenProceed}
            />
          );
        },
        rowExpandable: (record) =>
          record.channelOrderDisplayId === record.channelOrderDisplayId,
      }}
      dataSource={datos}
      pagination={{
        defaultCurrent: 1,
        onChange: onChangePage,
        total: documents,
        pageSize: limit,
      }}
    />
  );
}
