import gql from "graphql-tag";

const RESTAURANT = gql`
  query getEstablishmentForDetails(
    $slug: String
    $domain: String
    $language: String
  ) {
    getEstablishmentForDetails(
      slug: $slug
      domain: $domain
      language: $language
    ) {
      messages
      success
      status
      data {
        _id
        title
        image
        llevar
        delivery
        description
        rating
        anadidoFavorito
        inHouse
        adsInHouse
        slug
        inOffert
        type
        salvingPack
        forttalezaURL
        noScheduled
        contactCode
        openURL
        url
        aceptReservation
        reservationScheduled
        socialLink {
          instagram
          facebook
          twitter
          web
        }
        scheduleOnly {
          available
          hour
        }
        adress {
          calle
          numero
          codigoPostal
          ciudad
          lat
          lgn
        }
        city
        categoryID
        autoshipping
        collections
        isDeliverectPartner
        alegeno_url
        categoryName
        minime
        stimateTime
        ispartners
        highkitchen
        shipping
        previous_shipping
        stimateTime
        highkitchen
        extras
        menu
        tipo
        isnew
        phone
        email
        logo
        apertura
        cierre
        aperturaMin
        cierreMin
        diaslaborales
        open
        OnesignalID
        ispartners
        schedule {
          Monday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }

          Tuesday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }

          Wednesday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }

          Thursday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }

          Friday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }

          Saturday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }

          Sunday {
            day
            isOpen
            openHour1
            openHour2
            openMinute1
            openMinute2
            closetHour1
            closetHour2
            closetMinute1
            closetMinute2
          }
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query getNewProductoaAllStore(
    $store: String
    $locationType: [String]
    $domain: String
    $language: String
  ) {
    getNewProductoaAllStore(
      store: $store
      locationType: $locationType
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        isOpen
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        capacityUsages
        parentId
        visible
        snoozed
        recomended
        quantity
        new
        popular
        offert
        previous_price
        subProductSortOrder
        internalId
        related
        locationType
        priceInHouse
        previous_priceInHouse
        priceInHouse1
        previous_priceInHouse1
        label
        label1
        alergenos
      }
    }
  }
`;

const GET_PRODUCT_SEARCH = gql`
  query getNewProductoSearchStore(
    $store: String
    $search: String
    $locationType: [String]
    $domain: String
    $language: String
  ) {
    getNewProductoSearchStore(
      store: $store
      search: $search
      locationType: $locationType
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        isOpen
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        capacityUsages
        parentId
        visible
        snoozed
        recomended
        quantity
        new
        popular
        offert
        previous_price
        subProductSortOrder
        internalId
        related
        locationType
        priceInHouse
        previous_priceInHouse
        priceInHouse1
        previous_priceInHouse1
        label
        label1
        alergenos
      }
    }
  }
`;

const GET_PRODUCT_BUNDLED = gql`
  query getNewProductoBundled(
    $store: String
    $domain: String
    $language: String
  ) {
    getNewProductoBundled(store: $store, domain: $domain, language: $language) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        isOpen
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        related
        locationType
        max
        min
        capacityUsages
        parentId
        visible
        snoozed
        recomended
        quantity
        new
        popular
        offert
        previous_price
        subProductSortOrder
        internalId
      }
    }
  }
`;

const NEW_MENU = gql`
  query getNewMenu($id: String, $domain: String, $language: String) {
    getNewMenu(id: $id, domain: $domain, language: $language) {
      message
      success
      list {
        _id
        menu
        menuId
        description
        menuImageURL
        menuType
        availabilities
        productTags
        currency
        validations
        nestedModifiers
        channelLinkId
        storeId
      }
    }
  }
`;

const GET_BUNDLED = gql`
  query getBundled(
    $products: [String]
    $storeID: ID
    $domain: String
    $language: String
  ) {
    getBundled(
      products: $products
      storeID: $storeID
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        recomended
        quantity
        capacityUsages
        parentId
        visible
        snoozed
        subProductSortOrder
        internalId
        Products {
          _id
          name
          description
          account
          location
          productType
          plu
          price
          sortOrder
          deliveryTax
          takeawayTax
          multiply
          multiMax
          posProductId
          posProductCategoryId
          subProducts
          productTags
          posCategoryIds
          imageUrl
          max
          related
          locationType
          min
          capacityUsages
          parentId
          visible
          recomended
          quantity
          snoozed
          subProductSortOrder
          internalId
        }
      }
    }
  }
`;

const GET_MODIFIELD = gql`
  query getModifieldGroup(
    $products: [String]
    $storeID: ID
    $domain: String
    $language: String
  ) {
    getModifieldGroup(
      products: $products
      storeID: $storeID
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        recomended
        quantity
        capacityUsages
        parentId
        visible
        snoozed
        subProductSortOrder
        internalId
        Modifiers {
          _id
          name
          description
          account
          location
          productType
          plu
          price
          sortOrder
          deliveryTax
          takeawayTax
          multiply
          multiMax
          posProductId
          posProductCategoryId
          subProducts
          productTags
          posCategoryIds
          imageUrl
          max
          min
          recomended
          quantity
          capacityUsages
          parentId
          visible
          snoozed
          subProductSortOrder
          internalId
          ModifierGroups {
            _id
            name
            description
            account
            location
            productType
            plu
            price
            sortOrder
            deliveryTax
            takeawayTax
            multiply
            multiMax
            posProductId
            posProductCategoryId
            subProducts
            productTags
            posCategoryIds
            imageUrl
            max
            min
            recomended
            quantity
            capacityUsages
            parentId
            visible
            snoozed
            subProductSortOrder
            internalId
            Modifiers {
              _id
              name
              description
              account
              location
              productType
              plu
              price
              sortOrder
              deliveryTax
              takeawayTax
              multiply
              multiMax
              posProductId
              posProductCategoryId
              subProducts
              productTags
              posCategoryIds
              imageUrl
              max
              min
              recomended
              quantity
              capacityUsages
              parentId
              visible
              snoozed
              subProductSortOrder
              internalId
            }
          }
        }
      }
    }
  }
`;

const GET_BUNDLED_STORE = gql`
  query getBundledStore($storeID: ID, $domain: String, $language: String) {
    getBundledStore(storeID: $storeID, domain: $domain, language: $language) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        recomended
        quantity
        capacityUsages
        parentId
        visible
        snoozed
        subProductSortOrder
        internalId
        Products {
          _id
          name
          description
          account
          location
          productType
          plu
          price
          sortOrder
          deliveryTax
          takeawayTax
          multiply
          multiMax
          posProductId
          posProductCategoryId
          subProducts
          productTags
          posCategoryIds
          imageUrl
          locationType
          related
          max
          min
          capacityUsages
          parentId
          visible
          recomended
          quantity
          snoozed
          subProductSortOrder
          internalId
        }
      }
    }
  }
`;

const GET_MODIFIELD_STORE = gql`
  query getModifiedGroupStore(
    $storeID: ID
    $domain: String
    $language: String
  ) {
    getModifiedGroupStore(
      storeID: $storeID
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        recomended
        quantity
        capacityUsages
        parentId
        visible
        snoozed
        subProductSortOrder
        internalId
        Modifiers {
          _id
          name
          description
          account
          location
          productType
          plu
          price
          sortOrder
          deliveryTax
          takeawayTax
          multiply
          multiMax
          posProductId
          posProductCategoryId
          subProducts
          productTags
          posCategoryIds
          imageUrl
          max
          min
          recomended
          quantity
          capacityUsages
          parentId
          visible
          snoozed
          subProductSortOrder
          internalId
          ModifierGroups {
            _id
            name
            description
            account
            location
            productType
            plu
            price
            sortOrder
            deliveryTax
            takeawayTax
            multiply
            multiMax
            posProductId
            posProductCategoryId
            subProducts
            productTags
            posCategoryIds
            imageUrl
            max
            min
            recomended
            quantity
            capacityUsages
            parentId
            visible
            snoozed
            subProductSortOrder
            internalId
            Modifiers {
              _id
              name
              description
              account
              location
              productType
              plu
              price
              sortOrder
              deliveryTax
              takeawayTax
              multiply
              multiMax
              posProductId
              posProductCategoryId
              subProducts
              productTags
              posCategoryIds
              imageUrl
              max
              min
              recomended
              quantity
              capacityUsages
              parentId
              visible
              snoozed
              subProductSortOrder
              internalId
            }
          }
        }
      }
    }
  }
`;

const GET_MODIFIELDS = gql`
  query getNewProductoModifield(
    $store: ID
    $domain: String
    $language: String
  ) {
    getNewProductoModifield(
      store: $store
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        recomended
        quantity
        capacityUsages
        parentId
        visible
        snoozed
        subProductSortOrder
        internalId
      }
    }
  }
`;

const GET_ORDER = gql`
  query getNewOrderStore(
    $status: [String]
    $search: JSON
    $dateRange: DateRangeInput
    $page: Int
    $limit: Int
    $domain: String
    $language: String
  ) {
    getNewOrderStore(
      status: $status
      search: $search
      dateRange: $dateRange
      page: $page
      limit: $limit
      domain: $domain
      language: $language
    ) {
      success
      message
      status
      count
      data {
        _id
        channelOrderDisplayId
        orderType
        pickupTime
        estimatedPickupTime
        deliveryTime
        courier
        scheduled
        created_at
        tip
        courierData {
          _id
          name
          lastName
          email
          avatar
          city
          telefono
          created_at
          updated_at
          termAndConditions
          verifyPhone
          StripeID
          PaypalID
          OnesignalID
          rating
        }
        customerData {
          _id
          name
          lastName
          email
          avatar
          city
          telefono
          created_at
          updated_at
          termAndConditions
          verifyPhone
          StripeID
          PaypalID
          OnesignalID
        }
        customer
        store
        storeData
        deliveryAddressData {
          _id
          formatted_address
          puertaPiso
          city
          postalcode
          type
          usuario
          lat
          lgn
        }
        deliveryAddress
        orderIsAlreadyPaid
        payment
        note
        items
        numberOfCustomers
        deliveryCost
        serviceCharge
        cuponTipe
        cuponValue
        discountTotal
        IntegerValue
        paymentMethod
        statusProcess
        Needcutlery
        status
        invoiceUrl
      }
    }
  }
`;

const GET_NEW_PRODUCT_STORE = gql`
  query getNewProductoForStore(
    $products: [String]
    $storeID: ID
    $locationType: [String]
    $domain: String
    $language: String
  ) {
    getNewProductoForStore(
      products: $products
      storeID: $storeID
      locationType: $locationType
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        _id
        name
        description
        account
        location
        productType
        plu
        price
        sortOrder
        isOpen
        deliveryTax
        takeawayTax
        multiply
        multiMax
        posProductId
        posProductCategoryId
        subProducts
        productTags
        posCategoryIds
        imageUrl
        max
        min
        capacityUsages
        parentId
        visible
        snoozed
        recomended
        quantity
        new
        popular
        offert
        previous_price
        subProductSortOrder
        internalId
        related
        locationType
        priceInHouse
        previous_priceInHouse
        priceInHouse1
        previous_priceInHouse1
        label
        label1
        alergenos
      }
    }
  }
`;

const GET_VALORACION = gql`
  query getValoracionesStore(
    $id: ID
    $page: Int
    $limit: Int
    $domain: String
    $language: String
  ) {
    getValoracionesStore(
      id: $id
      page: $page
      limit: $limit
      domain: $domain
      language: $language
    ) {
      messages
      success
      data {
        id
        comment
        value
        user
        created_at
        Usuario {
          _id
          name
          lastName
          email
          city
          avatar
          telefono
          created_at
          updated_at
          termAndConditions
          verifyPhone
          StripeID
          PaypalID
          OnesignalID
        }
      }
    }
  }
`;

const GET_RESERVAS = gql`
  query getAllBookin(
    $status: [String]
    $isUser: Boolean
    $isAdmin: Boolean
    $domain: String
    $language: String
    $page: Int
    $limit: Int
  ) {
    getAllBookin(
      status: $status
      isUser: $isUser
      isAdmin: $isAdmin
      domain: $domain
      language: $language
      page: $page
      limit: $limit
    ) {
      success
      messages
      count
      data {
        _id
        store
        user
        date
        hour
        people
        note
        city
        status
        userID
        storeID
        created_at
        updated_at
      }
    }
  }
`;

const GET_CONFIG_DOMAIN = gql`
  query getConfigDomain($domain: String) {
    getConfigDomain(domain: $domain) {
      success
      messages
      status
      data {
        _id
        domain
        shippingPrices
        serviceFees
        kosmoIntegration
      }
    }
  }
`;

export const query = {
  RESTAURANT,
  GET_ORDER,
  NEW_MENU,
  GET_PRODUCT,
  GET_BUNDLED,
  GET_MODIFIELD,
  GET_PRODUCT_BUNDLED,
  GET_BUNDLED_STORE,
  GET_MODIFIELD_STORE,
  GET_MODIFIELDS,
  GET_PRODUCT_SEARCH,
  GET_NEW_PRODUCT_STORE,
  GET_VALORACION,
  GET_RESERVAS,
  GET_CONFIG_DOMAIN,
};
