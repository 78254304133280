import React from "react";
import Router from "./Router/router";
import Session from "./sesion";
import "./index.css";

function App() {
  return <Router />;
}

const RootSession = Session(App);

export { RootSession };
